<template>
  <div class="relative z-10 @container">
    <ImageGridDesktop :rows="data.rows" />
    <ImageGridMobile
      v-if="isMobile"
      :display-images="displayImages"
      :small-images-groups="smallImagesGroups"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useIsMobile } from '@autobid/ui/composables/useIsMobile'
import type { Image } from '@autobid/ui/types/components/Image'
import type { ImageGrid } from '@autobid/ui/types/components/ImageGrid'
import ImageGridDesktop from './ImageGridDesktop.vue'
import ImageGridMobile from './ImageGridMobile.vue'

interface Props {
  data: {
    rows: ImageGrid[]
  }
}

const props = defineProps<Props>()

const allImages = computed(() => {
  return props.data.rows.reduce((acc, row) => {
    return [...acc, ...row.images]
  }, [] as Image[])
})

const smallImages = computed(() => {
  return allImages.value.filter((image) => image.width === 'w-1/6')
})

const smallImagesGroups = computed(() => {
  let pointer = 0
  return smallImages.value.reduce((acc, curr, index) => {
    if (!acc[pointer]) {
      acc[pointer] = []
    }
    acc[pointer].push(curr)

    if (index % 2 !== 0) {
      pointer++
    }

    return acc
  }, []) as Image[][]
})

const displayImages = computed(() => {
  return allImages.value.filter((image) => image.width !== 'w-1/6')
})

const isMobile = useIsMobile()
const isSliderRegistered = ref(false)

watch(isMobile, async () => {
  if (!isMobile || isSliderRegistered.value) {
    return
  }
  const { register } = await import('swiper/element/bundle')
  register()
  isSliderRegistered.value = true
})
</script>
